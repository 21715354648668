class Token {

    constructor(options) {
        this.options = Object.assign({
            token:            null,
            onBeforeExpire:   () => {},
            onAfterExpire:    () => {},
        }, options);

        if (this.options.token) {
            this.update(this.options.token, true);
        }

        this.after = null;
        this.before = null;
    }

    update(token, initial) {
        if (this.before) {
            clearTimeout(this.before);
        }

        if (this.after) {
            clearTimeout(this.after);
        }
        
        let [ meta, payload, signature ] = token.split('=');
        
        meta = JSON.parse(base32.parse(meta));

        let diff = null;

        if (meta.ttl) {
            let expires = Math.round(meta.created) + (meta.ttl * 1000);
            diff = expires - (new Date().getTime());
        }

        if (meta.payload == 'base32') {
            payload = JSON.parse(base32.parse(payload));
        } else {
            payload = null;
        }

        this.token = {
            valid:      diff === null || diff > 0,
            token:      token,
            meta:       meta,
            payload:    payload,
            signature:  signature,
        };


        /* Check for server/client time mismatch */

        if (!initial) {
            let age = 0 - Math.round((meta.created - (new Date().getTime())) / 1000);

            if (Math.abs(age) > 60 * 15) {
                return;
            }
        }


        /* Check for token expiration */

        if (diff !== null) {
            let beforeExpiration = diff - (60 * 1000);

            if (beforeExpiration > 0) {
                this.before = setTimeout(() => {
                    this.options.onBeforeExpire();
                }, beforeExpiration);

                this.after = setTimeout(() => {
                    this.options.onAfterExpire();
                }, diff);
            }
            else if (diff > 0) {
                this.options.onBeforeExpire();

                this.after = setTimeout(() => {
                    this.options.onAfterExpire();
                }, diff);
            }
            else {
                this.options.onAfterExpire();
            }
        }
    }

    get valid() {
        if (!this.token) {
            return false;
        }

        let meta = this.token.meta;

        if (!meta.ttl) {
            return true;
        }

        let expires = Math.round(meta.created) + (meta.ttl * 1000);
        let diff = expires - (new Date().getTime());

        return diff > 0;
    }

    get expires() {
        if (!this.token) {
            return null;
        }

        let meta = this.token.meta;
        let expires = Math.round(meta.created) + (meta.ttl * 1000);
        let diff = expires - (new Date().getTime());

        return Math.round(diff / 1000);
    }

    get ttl() {
        if (!this.token) {
            return null;
        }

        return this.token.meta.ttl;
    }

    get value() {
        if (!this.token) {
            return null;
        }

        return this.token.token;
    }
}